<template lang="html">
    <div style="height:100%">
        <!-- 第一行 搜索栏 -->
        <div class="handleBox flex flex-jc-sb flex-ai-c" @keyup.enter="curr=1;getList()">
            <p class="title">学生管理 ></p>
            <div class="">
            </div>
        </div>
        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 95px )">
            <el-table :data="list" highlight-current-row stripe border
                :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}"
                @sort-change='getList()'
                height="calc( 100% - 41px )"
            >
                <el-table-column label="序号" type="index" align="center" show-overflow-tooltip width="70">
                </el-table-column>
                <!-- <el-table-column prop="headimgurl" label="头像">
                    <template slot-scope="scope">
                        <img :src="scope.row.headimgurl" alt="">
                    </template>
                </el-table-column> -->
                <el-table-column prop="openid" label="openid" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            title: '添加人员',
            searchValue: '', //导入input值
            list: [],
            curr: 1, //页码
            row: 20, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList()
    },
    watch: {

    },
    methods: {
        getList: function() {
            this.http.post('/admin.student/getList', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue
            }).then(re => {
                this.list = re.list;
                this.curr = re.curr;
                this.pages = re.pages;
                this.count = re.count;
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
